<template>
  <b-nav-item-dropdown
    ref="dropdown"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="unreadCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Thông báo
        </h4>
        <!-- <b-badge
          pill
          variant="light-primary"
        >
          6 Thông báo mới
        </b-badge> -->
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :key="renderKey"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification in notifications"
        :key="notification.id"
        @click="handleNotificationClick(notification)"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :src="notification.avatar"
              :text="notification.avatar"
              :variant="notification.type"
            />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.body }}</small>
        </b-media>
      </b-link>

    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <span
        v-if="notifications.length === 0"
        class="text-secondary text-center"
      >Bạn chưa có thông báo nào</span>
      <b-button
        v-else
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="openNotificationListView"
      >Xem tất cả</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BMedia,
  BLink,
  BAvatar,
  BButton,
} from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BNavItemDropdown,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      notifications: [],
      unreadCount: 0,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      renderKey: 0,
    };
  },
  created() {

  },

  methods: {

    openNotificationListView() {
      this.$refs.dropdown.hide();
      if (this.$router.history.current.name !== 'system-notification') {
        this.$router
          .replace({ name: 'system-notification' });
      }
    },
    handleNotificationClick(item) {
      this.$refs.dropdown.hide();
      if (item.payloadId > 0) {
        if (item.payloadType === 'task') {
          this.$router.push({ name: 'apps-todo', params: { taskId: item.payloadId }, query: { t: (new Date()).getTime() } });
        } else if (item.payloadType === 'contract') {
          this.$router.push({ name: 'contract-list', params: { contractId: item.payloadId }, query: { t: (new Date()).getTime() } });
        }
      }
    },
  },

  setup() {

  },

};
</script>

<style></style>
